import { Link as RouterLink } from 'react-router-dom';
import '../utils/animations.css';
// @mui
import { BoxProps } from '@mui/material';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
import logo from '../utils/images/logo.png';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { isCollapse } = useCollapseDrawer();
  if (isCollapse) return <RouterLink to="/">{<img src={String(logo)} alt="Logo" width="45" height="45" />}</RouterLink>;
  return <RouterLink to="/">{<img className="rotateOnHover" src={String(logo)} alt="Logo" width="65" height="65" />}</RouterLink>;
}
