import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { addUser } from '../../API/API';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import { IReloadFunctions } from '../../Types';
import useLocales from '../../../../../hooks/useLocales';

interface IAddUserModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  reloadFunctions: IReloadFunctions;
}

export default function AddUserModal(props: IAddUserModal) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');

  async function onSubmit() {
    try {
      setLoading(true);
      await addUser(firstname, lastname, email);
      setLoading(false);
      setFirstname('');
      setLastname('');
      setEmail('');
      setTimeout(() => {
        props.reloadFunctions.loadUsers();
        props.setModalState(false);
      });
    } catch (error) {
      setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_adding_user'), 'error_adding_user');
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '450px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid container direction="column" alignItems="center" style={{ padding: 20, width: '100%' }}>
          <Grid item>{t('add_user')}</Grid>
          <Grid item container direction="row" style={{ marginTop: 15, width: '100%' }}>
            <Grid item flex={1} style={{ display: 'flex', alignItems: 'center' }}>
              {t('firstname')}:
            </Grid>
            <Grid item flex={2}>
              <TextField value={firstname} onChange={(info) => setFirstname(info.target.value)} fullWidth />
            </Grid>
          </Grid>
          <Grid item container direction="row" style={{ marginTop: 15, width: '100%' }}>
            <Grid item flex={1} style={{ display: 'flex', alignItems: 'center' }}>
              {t('lastname')}:
            </Grid>
            <Grid item flex={2}>
              <TextField value={lastname} onChange={(info) => setLastname(info.target.value)} fullWidth />
            </Grid>
          </Grid>
          <Grid item container direction="row" style={{ marginTop: 15, width: '100%' }}>
            <Grid item flex={1} style={{ display: 'flex', alignItems: 'center' }}>
              E-Mail:
            </Grid>
            <Grid item flex={2}>
              <TextField value={email} onChange={(info) => setEmail(info.target.value)} fullWidth />
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 40 }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={onSubmit} loading={loading} disabled={firstname === '' || lastname === '' || email === ''}>
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
