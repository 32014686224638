import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//configuration for success notification
export function notifySuccess(message: string, Id: string, showBottom?: boolean) {
  if (toast.isActive(Id)) {
    toast.update(Id, { render: message });
  } else {
    toast.success(message, {
      toastId: Id,
      position: showBottom ? 'bottom-center' : 'top-left',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

//configuration for error notification
export function notifyError(message: string, Id: string, showBottom?: boolean) {
  if (toast.isActive(Id)) {
    toast.update(Id, { render: message });
  } else {
    toast.error(message, {
      toastId: Id,
      position: showBottom ? 'bottom-center' : 'top-left',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

//configuration for warning notification
export function notifyWarning(message: string, Id: string, showBottom?: boolean, stay?: boolean) {
  if (toast.isActive(Id)) {
    toast.update(Id, { render: message });
  } else {
    toast.warning(message, {
      toastId: Id,
      position: showBottom ? 'bottom-center' : 'top-left',
      autoClose: stay ? false : 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

//configuration for info notification
export function notifyInfo(message: string, Id: string, showBottom?: boolean, stay?: boolean) {
  if (toast.isActive(Id)) {
    toast.update(Id, { render: message });
  } else {
    toast.info(message, {
      toastId: Id,
      position: showBottom ? 'bottom-center' : 'top-left',
      autoClose: stay ? false : 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export function cancelNotification(Id: string) {
  toast.dismiss(Id);
}

export function cancelAllNotifications() {
  toast.dismiss();
}
