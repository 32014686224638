import { configureStore } from '@reduxjs/toolkit';
import projectDataSlice from './reducers/projectDataSlice';
import metadataSlice from './reducers/metadataSlice';
import permissionSlice from './reducers/permissionSlice';
import loadingSlice from './reducers/loadingSlice';

export const store = configureStore({
  reducer: {
    metadataSlice,
    projectDataSlice,
    permissionSlice,
    loadingSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
