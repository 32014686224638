import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProjectInfo } from '../../pages/ProjectManagement/Types/Types';

interface IProjectData {
  projectId: number;
  projectName: string;
  kustoTable: string;
  projectType: string;
  projectSitesTab: number;
  projectInfo: IProjectInfo | null;
  dashboard_published: string | null;
  data_input: string| null;
}

const defaultValue: IProjectData = localStorage.getItem('projectData')
  ? JSON.parse(localStorage.getItem('projectData') as string)
  : {
      projectId: 0,
      projectName: '',
      kustoTable: '',
      projectType: '',
      projectSitesTab: 0,
      dashboard_published: null,
      data_input: null
    };

const projectDataSlice = createSlice({
  name: 'projectData',
  initialState: defaultValue,
  reducers: {
    setProjectData(state, action: PayloadAction<IProjectData>) {
      localStorage.setItem('projectData', JSON.stringify(action.payload));
      Object.assign(state, action.payload);
    },
    updateActiveTab(state, action: PayloadAction<number>) {
      const projectData = localStorage.getItem('projectData');
      let data: any = {};
      if (projectData) {
        data = JSON.parse(projectData);
      }
      data['projectSitesTab'] = action.payload;
      localStorage.setItem('projectData', JSON.stringify(data));
      state.projectSitesTab = action.payload;
    },
  },
});

export const { setProjectData, updateActiveTab } = projectDataSlice.actions;

export default projectDataSlice.reducer;
