import { Button, Grid, Modal } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import useLocales from '../hooks/useLocales';

interface IAreYouSureModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  callIfYes: Function;
  text?: string;
}

export default function AreYouSureModal(props: IAreYouSureModal) {
  const { t } = useLocales();

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '360px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
        }}
      >
        <Grid container direction="column">
          <Grid item display="flex" justifyContent="center">
            {props.text && <h2>{props.text}</h2>}
            {!props.text && <h2>{t('are_you_sure')}</h2>}
          </Grid>
          <Grid item container direction="row" justifyContent="center" style={{ marginTop: '20px' }}>
            <Grid item>
              <Button variant="outlined" style={{ marginRight: 5 }} onClick={() => props.setModalState(false)}>
                {t('no')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  props.callIfYes();
                  props.setModalState(false);
                }}
              >
                {t('yes')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
