import { Button } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import './loader.css';
const logo = require('../images/logo_bigger.png');

interface LoaderProps {
  dataIsLoading: boolean;
  errorState: boolean;
  retry: Function | undefined;
  positionFix?: boolean;
}

export default function Loader(props: LoaderProps) {
  const { t } = useLocales();
  return (
    <div className={props.dataIsLoading || props.errorState ? 'loader' : 'invisible'} style={{ zIndex: 999, position: props.positionFix ? 'fixed' : 'absolute' }}>
      <div className={!props.errorState ? 'loader' : 'invisible'}>
        <div className="loaderText">{t('loading_data')}</div>
        <div className={'loaderAnimation'}>
          <div className={'loading'}>
            <img src={String(logo)} alt="loadingLogo" width="100" height="100" />
          </div>
        </div>
      </div>
      <div className={props.errorState ? 'loader' : 'invisible'}>
        <p className="errorText">{t('ups_error_loading_data')}</p>
        <Button
          variant="contained"
          onClick={() => {
            if (props.retry) props.retry();
          }}
        >
          {t('retry')}
        </Button>
      </div>
    </div>
  );
}
