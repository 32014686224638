import { Grid } from '@mui/material';
import RolesTable from './Tables/RolesTable';
import { useEffect, useState } from 'react';
import { IAction, IPage, IReloadFunctions, IRole } from '../Types';
import { getRoleActions, getRolePages } from '../API/API';
import ActionsTable from './Tables/ActionsTable';
import PagesTable from './Tables/PagesTable';
import useAuth from '../../../../hooks/useAuth';
import { handleApiError } from '../../../../utils/HandleApiError';
import { notifyError } from '../../../../utils/Notification';
import useLocales from '../../../../hooks/useLocales';

interface IRoleManager {
  reloadFunctions: IReloadFunctions;
}

export default function RoleManager(props: IRoleManager) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [selRole, setSelRole] = useState<IRole | null>(null);
  const [roleActions, setRoleActions] = useState<IAction[]>([]);
  const [rolePages, setRolePages] = useState<IPage[]>([]);

  async function loadRoleData(role: IRole) {
    try {
      props.reloadFunctions.setLoading(true);
      const res = await getRoleActions(role.Id);
      setRoleActions(res.data);
      const res2 = await getRolePages(role.Id);
      setRolePages(res2.data);
      setTimeout(() => props.reloadFunctions.setLoading(false));
    } catch (error) {
      props.reloadFunctions.setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_loading_data'), 'error_loading_role_data');
    }
  }

  useEffect(() => {
    if (selRole) {
      loadRoleData(selRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selRole]);

  return (
    <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
      <Grid item container flex={2} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('user_roles')}
        </Grid>
        <Grid item flex={1}>
          <RolesTable setSelRole={setSelRole} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
      <Grid item style={{ height: '100%', width: '5px', borderStyle: 'solid', borderWidth: '2px' }}></Grid>
      <Grid item container flex={2} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('actions')}
        </Grid>
        <Grid item flex={1}>
          <ActionsTable roleActions={roleActions} selRole={selRole} loadRoleData={loadRoleData} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
      <Grid item container flex={2} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('pages')}
        </Grid>
        <Grid item flex={1}>
          <PagesTable rolePages={rolePages} selRole={selRole} loadRoleData={loadRoleData} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
    </Grid>
  );
}
