import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import UserTable from './Tables/UserTable';
import { getUsersRoles } from '../API/API';
import { IReloadFunctions, IRoleData, ITreeStructure, IUser } from '../Types';
import RolesTable from './Tables/RolesTable';
import { handleApiError } from '../../../../utils/HandleApiError';
import useAuth from '../../../../hooks/useAuth';
import { notifyError } from '../../../../utils/Notification';
import useLocales from '../../../../hooks/useLocales';

interface IUserPermissionManager {
  reloadFunctions: IReloadFunctions;
}

export default function UserPermissionManager(props: IUserPermissionManager) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [selUser, setSelUser] = useState<IUser | null>(null);
  const [usersRoles, setUsersRoles] = useState<ITreeStructure[]>([]);

  function createTableTree(data: IRoleData[]) {
    const root: ITreeStructure[] = [];
    data.forEach((allo) => {
      const projIndex = root.findIndex((val) => val.Id === allo.projectId);
      if (projIndex === -1) {
        root.push({
          Id: allo.projectId,
          name: allo.projectId === -1 ? '--ALL--' : allo.projectLabel,
          folder: true,
          nodes: [
            {
              Id: allo.buildingId,
              name: allo.buildingId === -1 ? '--ALL--' : allo.buildingLabel,
              folder: true,
              nodes: [
                {
                  Id: allo.Id,
                  name: allo.name,
                  roleData: allo,
                },
              ],
            },
          ],
        });
      } else {
        const buildingIndex = root[projIndex].nodes!.findIndex((val) => val.Id === allo.buildingId);
        if (buildingIndex === -1) {
          root[projIndex].nodes?.push({
            Id: allo.buildingId,
            name: allo.buildingId === -1 ? '--ALL--' : allo.buildingLabel,
            folder: true,
            nodes: [
              {
                Id: allo.Id,
                name: allo.name,
                roleData: allo,
              },
            ],
          });
        } else {
          root[projIndex].nodes![buildingIndex].nodes?.push({
            Id: allo.Id,
            name: allo.name,
            roleData: allo,
          });
        }
      }
    });
    return root;
  }

  async function loadUserData(user: IUser) {
    try {
      props.reloadFunctions.setLoading(true);
      const res = await getUsersRoles(user.Id);
      setUsersRoles(createTableTree(res.data));
      setTimeout(() => props.reloadFunctions.setLoading(false));
    } catch (error) {
      props.reloadFunctions.setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_loading_data'), 'error_loading_user_data');
    }
  }

  useEffect(() => {
    if (selUser) {
      loadUserData(selUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selUser]);

  return (
    <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
      <Grid item container flex={1} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('user')}
        </Grid>
        <Grid item flex={1}>
          <UserTable setSelUser={setSelUser} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
      <Grid item style={{ height: '100%', width: '5px', borderStyle: 'solid', borderWidth: '2px' }}></Grid>
      <Grid item container flex={1} direction="column">
        {selUser && (
          <>
            <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
              {t('user_roles')}
            </Grid>
            <Grid item flex={1}>
              <RolesTable usersRoles={usersRoles} selUser={selUser} loadUserData={loadUserData} reloadFunctions={props.reloadFunctions} />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
