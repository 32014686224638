import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { scrollProps } from '../../../../../utils/ScrollProps';
import { IReloadFunctions, IUser } from '../../Types';
import { TypeEditInfo, TypeRowProps } from '@inovua/reactdatagrid-enterprise/types';
import AddUserModal from '../Modals/AddUserModal';
import ConfirmDeleteModal from '../../../../../utils/ConfirmDeleteModal';
import { deleteUser, getTwoFactorQrCode, updateUserState } from '../../API/API';
import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
import { RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useLocales from '../../../../../hooks/useLocales';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';

interface IUserTable {
  setSelUser: Dispatch<SetStateAction<IUser | null>>;
  reloadFunctions: IReloadFunctions;
}

export default function UserTable(props: IUserTable) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [addUserModalState, setAddUserModalState] = useState(false);
  const [contextRow, setContextRow] = useState<IUser | null>(null);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const users = useSelector((state: RootState) => state.permissionSlice.users);
  const isLoading = useSelector((state: RootState) => state.loadingSlice.isLoading);

  const renderRowContextMenu = (menuProps: any, info: any) => {
    if (info.cellProps.data.name !== 'Exists') {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: t('delete'),
          onClick: () => {
            setContextRow(info.cellProps.data);
            setDeleteModalState(true);
          },
        },
        {
          label: t('copy'),
          onClick: () => {
            navigator.clipboard.writeText(info.cellProps.value); //copy cell value to clipboard
          },
        },
        {
          label: t('get_2_factor_qr'),
          onClick: async () => {
            await downloadTwoFactorQrCode(info.cellProps.data.Id);
          },
        },
      ];
    }
  };

  async function downloadTwoFactorQrCode(userId: number) {
    try {
      const res = await getTwoFactorQrCode(userId);
      let a = document.createElement('a');
      a.href = 'data:image/png;base64,' + res.data;
      a.download = 'twoFactor_' + userId + '.png';
      a.click();
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteSelUser() {
    try {
      if (contextRow) {
        props.reloadFunctions.setLoading(true);
        await deleteUser(contextRow.Id);
        props.setSelUser(null);
        props.reloadFunctions.loadUsers();
      }
    } catch (error) {
      props.reloadFunctions.setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_deleting_user'), 'error_deleting_user');
    }
  }

  async function onEditComplete(editInfo: TypeEditInfo) {
    //@ts-ignore
    const oldValue = editInfo.data[editInfo.columnId]; //get old value
    const newValue = editInfo.value; //get new value
    if (oldValue !== newValue) {
      try {
        props.reloadFunctions.setLoading(true);
        //@ts-ignore
        const user: IUser = { ...editInfo.data };
        //@ts-ignore
        user[editInfo.columnId] = editInfo.value;
        await updateUserState(user.Id, user.disabled, user.isAdmin);
        props.reloadFunctions.loadUsers();
      } catch (error) {
        props.reloadFunctions.setLoading(false);
        handleApiError(error, logout);
        notifyError(t('error_changing_user_state'), 'error_changing_user_state');
      }
    }
  }

  return (
    <>
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_DATA_GRID_KEY}
        scrollProps={scrollProps}
        idProperty="Id"
        columns={[
          {
            name: 'Id',
            header: 'Id',
            headerAlign: 'center',
            editable: false,
            visible: false,
          },
          {
            name: 'firstname',
            header: t('firstname'),
            headerAlign: 'center',
            editable: false,
            defaultFlex: 1,
          },
          {
            name: 'lastname',
            header: t('lastname'),
            headerAlign: 'center',
            editable: false,
            defaultFlex: 1,
          },
          {
            name: 'email',
            header: 'E-Mail',
            headerAlign: 'center',
            editable: false,
            defaultFlex: 1,
          },
          {
            name: 'isAdmin',
            header: 'Admin',
            headerAlign: 'center',
            editable: true,
            width: 120,
            //@ts-ignore
            editor: BoolEditor,
            filterEditor: BoolFilter,
            render: ({ value, data }) => {
              if (value) {
                return <div>{t('yes')}</div>;
              }
              return <div>{t('no')}</div>;
            },
          },
          {
            name: 'disabled',
            header: t('disabled'),
            headerAlign: 'center',
            editable: true,
            width: 120,
            //@ts-ignore
            editor: BoolEditor,
            filterEditor: BoolFilter,
            render: ({ value, data }) => {
              if (value) {
                return <div>{t('yes')}</div>;
              }
              return <div>{t('no')}</div>;
            },
          },
        ]}
        defaultFilterValue={[
          { name: 'firstname', operator: 'contains', type: 'string', value: '' },
          { name: 'lastname', operator: 'contains', type: 'string', value: '' },
          { name: 'email', operator: 'contains', type: 'string', value: '' },
          { name: 'isAdmin', operator: 'eq', type: 'boolean', value: null },
          { name: 'disabled', operator: 'eq', type: 'boolean', value: null },
        ]}
        dataSource={users}
        style={{ minHeight: '100%' }}
        enableColumnAutosize
        onRowClick={(rowProps: TypeRowProps, event: MouseEvent) => {
          props.setSelUser(rowProps.data);
        }}
        enableSelection
        renderRowContextMenu={renderRowContextMenu}
        onEditComplete={onEditComplete}
        columnUserSelect
      />
      {!isLoading && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <Fab onClick={() => setAddUserModalState(true)} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
      <AddUserModal modalState={addUserModalState} setModalState={setAddUserModalState} reloadFunctions={props.reloadFunctions} />
      <ConfirmDeleteModal modalState={deleteModalState} setModalState={setDeleteModalState} callIfYes={deleteSelUser} deletingItemText={t('user') + ' ' + contextRow?.firstname} />
    </>
  );
}
