import { useEffect, useState } from 'react';
// @mui
import { Box, List, Badge, Tooltip, Divider, IconButton, Typography, ListItemText, ListSubheader, ListItemButton } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import axios from '../../../utils/axios';
import useLocales from '../../../hooks/useLocales';
import _ from 'lodash';
import { handleApiError } from '../../../utils/HandleApiError';
import useAuth from '../../../hooks/useAuth';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

interface INotification {
  Id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
}

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t } = useLocales();
  const { logout } = useAuth();
  let audio = new Audio('/notification_sound.mp3');
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalUnRead, setTotalUnRead] = useState(0);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  async function handleMarkAllAsRead() {
    const oldState = _.cloneDeep(notifications);
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
    try {
      await axios.post('notifications/setAllAsRead', {});
    } catch (error) {
      handleApiError(error, logout);
      setNotifications(oldState);
    }
  }

  async function deleteAll() {
    const oldState = _.cloneDeep(notifications);
    setNotifications([]);
    try {
      await axios.delete('notifications/delete/currentUser', {});
    } catch (error) {
      handleApiError(error, logout);
      setNotifications(oldState);
    }
  }

  async function loadData() {
    try {
      const res = await axios.get('notifications/all');
      let notificationList: INotification[] = res.data;
      notificationList.sort((noti1: INotification, noti2: INotification) => (new Date(noti1.createdAt) < new Date(noti2.createdAt) ? 1 : -1));
      setNotifications(notificationList);
    } catch (error) {
      handleApiError(error, logout);
    }
  }

  useEffect(() => {
    const unRead = notifications.filter((item: INotification) => item.isUnRead === true).length;
    if (unRead > 0) {
      audio.play();
    }
    setTotalUnRead(unRead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose} sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('notifications')}</Typography>
          </Box>
          {notifications.length > 0 && (
            <Tooltip title={t('delete_all')}>
              <IconButton color="primary" onClick={deleteAll}>
                <Iconify icon="eva:trash-2-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          {totalUnRead > 0 && (
            <Tooltip title={t('mark_all_as_read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/*<Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>*/}
        <Scrollbar style={{ maxHeight: '80vh' }}>
          <List
            disablePadding
            subheader={
              totalUnRead > 0 && (
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('new')}
                </ListSubheader>
              )
            }
          >
            {notifications.map((notification) => (notification.isUnRead ? <NotificationItem key={notification.Id} notification={notification} /> : null))}
          </List>

          <List
            disablePadding
            subheader={
              totalUnRead > 0 && (
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('before_that')}
                </ListSubheader>
              )
            }
          >
            {notifications.map((notification) => (notification.isUnRead ? null : <NotificationItem key={notification.Id} notification={notification} />))}
          </List>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{notifications.length === 0 && <Typography variant="subtitle2">{t('no_notifications')}</Typography>}</div>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification }: { notification: INotification }) {
  const { t, currentLang } = useLocales();
  const { title } = renderContent(notification, t);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt, currentLang.value === 'de' ? deLocale : enLocale)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: INotification, t: (text: string) => string) {
  const title = (
    <Typography variant="subtitle2">
      {t(notification.title)}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;{' '}
        {notification.description
          .replace('new_message_from', t('new_message_from'))
          .replace('in_todo', t('in_todo'))
          .replace('of_project', t('of_project'))
          .replace('new_todo_from', t('new_todo_from'))
          .replace('new_todo', t('new_todo'))}
      </Typography>
    </Typography>
  );

  return {
    title,
  };
}
