import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IPermissionData {
  isLoading: boolean;
}

const defaultValue: IPermissionData = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'permissionData',
  initialState: defaultValue,
  reducers: {
    setLoadingState(state, action: PayloadAction<IPermissionData>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setLoadingState } = loadingSlice.actions;

export default loadingSlice.reducer;
