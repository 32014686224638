import { Button, Grid, IconButton, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useLocales from '../hooks/useLocales';
import CachedIcon from '@mui/icons-material/Cached';

interface IDeleteModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  callIfYes: Function;
  deletingItemText: String;
}

export default function DeleteModal(props: IDeleteModal) {
  const { t } = useLocales();
  const [random, setRandom] = useState('');
  const [confirmCode, setConfirmCode] = useState('');

  function generateRandomString() {
    let text = '';
    const charSet = 'aAb0BcC1dDeE2fFgG3hHiI4jJkK5lLmMn6NoOp7PqQrR8sStT9uUvVwWxXyYzZ';
    for (var i = 0; i < 6; i++) text += charSet.charAt(Math.floor(Math.random() * charSet.length));
    setRandom(text);
  }

  useEffect(() => {
    if (props.modalState) {
      generateRandomString();
    } else {
      setConfirmCode('');
    }
  }, [props.modalState]);

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          maxWidth: '40vw',
          minWidth: '20vw',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
        }}
      >
        <Grid container direction="column" flex={1} alignItems="center" style={{ padding: 20 }} spacing={2}>
          <Grid item>
            <h4>
              {t('confirm_deleting')} {props.deletingItemText}
            </h4>
          </Grid>
          <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <p
                onCopy={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {random}
              </p>
            </Grid>
            <Grid item>
              <IconButton onClick={generateRandomString}>
                <CachedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <TextField label={t('repeat_code')} variant="standard" value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)} />
          </Grid>
          <Grid item style={{ marginTop: 40, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => {
                if (confirmCode === random) {
                  props.callIfYes();
                  props.setModalState(false);
                }
              }}
              disabled={confirmCode !== random}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
