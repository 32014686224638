// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useLocation, createBrowserRouter, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import { PATH_DASHBOARD } from './paths';
import PermissionGuard from '../guards/PermissionGuard';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
import ThemeProvider from '../theme';
import ThemeSettings from '../components/settings';
import { ToastContainer } from 'react-toastify';
import { ProgressBarStyle } from '../components/ProgressBar';
import ScrollToTop from '../components/ScrollToTop';

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//redux
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { SettingsDistributor } from '../contexts/SettingsDistributor';
import PermissionManagerRoot from '../pages/Administration/PermissionManager/PermissionManagerRoot';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  //eslint-disable-next-line react-hooks/rules-of-hooks
  //const { isAuthenticated } = useAuth();
  const isAuthenticated = false;

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const Home = Loadable(lazy(() => import('../pages/Home')));
const ProjectsJourFixes = Loadable(lazy(() => import('../pages/ProjectManagement/Pages/ProjectsJourFixePages/ProjectJourFixePageRoot')));
const ProjectRoot = Loadable(lazy(() => import('../pages/ProjectManagement/Pages/ProjectSites/ProjectRoot')));
const Calendar = Loadable(lazy(() => import('../pages/Calendar/Calendar')));
const DeviceSetup = Loadable(lazy(() => import('../pages/ProjectManagement/Pages/ProjectSites/DevicePage/DeviceSetupPage/DeviceSetup')));
const Tags = Loadable(lazy(() => import('../pages/Administration/Tags/Tags')));
const DeviceConfig = Loadable(lazy(() => import('../pages/DeviceConfig/DeviceConfig')));
const PresetEditor = Loadable(lazy(() => import('../pages/Administration/PresetEditor/Editor')));
const DeviceTypes = Loadable(lazy(() => import('../pages/Administration/DeviceTypes/DeviceTypes')));
const OperationStatePage = Loadable(lazy(() => import('../pages/Administration/OperationStates/OperationStateRoot')));
const SystemStatePage = Loadable(lazy(() => import('../pages/Administration/SystemState/SystemStatePage')));
const SystemStructure = Loadable(lazy(() => import('../pages/Administration/SystemStructure/SystemStructure')));
const VersionManagementRoot = Loadable(lazy(() => import('../pages/Administration/RulesVersionManagement/VersionManagementRoot')));
const CatalogPage = Loadable(lazy(() => import('../pages/Administration/OfferCreation/CatalogPage/CatalogPage')));
const OpticalCharacterRecognition = Loadable(lazy(() => import('../pages/Tools/OpticalCharacterRecognition/OpticalCharacterRecognition')));
const ZplPrinter = Loadable(lazy(() => import('../pages/Tools/ZplPrinter/ZplPrinter')));
const LunchFinder = Loadable(lazy(() => import('../pages/Administration/LunchFinder/LunchFinder')));

// MAIN
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsDistributor>
          <Provider store={store}>
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ToastContainer theme="colored" />
                  <ProgressBarStyle />
                  <ScrollToTop />
                  <Outlet />
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </Provider>
        </SettingsDistributor>
      </LocalizationProvider>
    ),
    children: [
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <Register />
              </GuestGuard>
            ),
          },
          { path: 'login-unprotected', element: <Login /> },
          { path: 'register-unprotected', element: <Register /> },
          { path: 'reset-password', element: <ResetPassword /> },
          { path: 'new-password', element: <NewPassword /> },
          { path: 'verify', element: <VerifyCode /> },
        ],
      },

      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={PATH_DASHBOARD.pages.home} replace />, index: true },
          {
            path: PATH_DASHBOARD.pages.home,
            element: <Home />,
          },
          {
            path: PATH_DASHBOARD.pages.projects,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="ae9ec4b1-7d5a-41d9-ac72-9a04f7c3b898">
                <ProjectsJourFixes />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.pages.projectSites,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="92ada00e-ebd6-4317-84ea-3c68187a0a1f">
                <ProjectRoot />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.pages.calendar,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="58285501-8281-4897-ad1d-adf8c9baf456">
                <Calendar />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.pages.deviceConfig,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="26832687-5414-4892-8df2-ba8a31cf1cc6">
                <DeviceConfig />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.pages.deviceSetup,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="0a738d41-1f92-4439-b1f4-0f7c038a383d">
                <DeviceSetup />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.tags,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="d280bdb2-ea1c-4f7a-9e26-21e18c449c03">
                <Tags />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.deviceTypes,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="5577058f-1282-4048-bad2-6b7e2917f2b3">
                <DeviceTypes />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.operationStates,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="f705668e-520c-480a-8ad7-4c2b4f4a6e81">
                <OperationStatePage />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.systemState,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="d6186f26-aa4f-4b71-8575-923ad14b2919">
                <SystemStatePage />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.systemStructure,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="e5ce5548-772d-4724-ab0f-0cdbc81330b4">
                <SystemStructure />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.presetEditor,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="41285870-5790-4765-bf7e-e2617e590bf2">
                <PresetEditor />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.mdsRulesVersionManagement,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="43f86f8e-d12f-4b0c-a92e-7feeb5be5f53">
                <VersionManagementRoot />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.offerCreator,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="a30cd2b0-b2e8-4f41-be1c-303985c6e2bc">
                <CatalogPage />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.administration.permissionManager,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="3827712b-cf2b-40de-97a6-d9d352520f2e">
                <PermissionManagerRoot />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.pages.lunchFinder,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="5afb9f13-e8e8-4973-aa0a-297cff230858">
                <LunchFinder />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.tools.orc,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="6c89ac3e-e686-46cc-80c6-34f5534c4042">
                <OpticalCharacterRecognition />
              </PermissionGuard>
            ),
          },
          {
            path: PATH_DASHBOARD.tools.zplPrinter,
            element: (
              <PermissionGuard hasContent={true} permissionUuid="20cb92ad-1060-4c0d-8774-2cb21603c98a">
                <ZplPrinter />
              </PermissionGuard>
            ),
          },
        ],
      },

      // Main Routes
      {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [
          { path: '404', element: <Page404 /> },
          { path: '*', element: <Navigate to="/404" replace /> },
        ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
]);
