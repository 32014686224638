import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { addRole } from '../../API/API';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import { IReloadFunctions } from '../../Types';
import useLocales from '../../../../../hooks/useLocales';

interface IAddRoleModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  reloadFunctions: IReloadFunctions;
}

export default function AddRoleModal(props: IAddRoleModal) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  async function onSubmit() {
    try {
      setLoading(true);
      await addRole(name);
      props.reloadFunctions.loadRoles();
      setLoading(false);
      props.setModalState(false);
      setName('');
    } catch (error) {
      setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_adding_permission_role'), 'error_adding_role');
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '450px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid container direction="column" alignItems="center" style={{ padding: 20, width: '100%' }}>
          <Grid item>{t('add_user_role')}</Grid>
          <Grid item container direction="row" style={{ marginTop: 15, width: '100%' }}>
            <Grid item flex={1} style={{ display: 'flex', alignItems: 'center' }}>
              Name:
            </Grid>
            <Grid item flex={2}>
              <TextField value={name} onChange={(info) => setName(info.target.value)} fullWidth />
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: 40 }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={onSubmit} loading={loading} disabled={name === ''}>
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
