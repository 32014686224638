export function highlightFolders(data: any) {
  if (data.data.outdated && data.data.isNew && data.data.folder) {
    return { background: '#ffff00' };
  }
  if (data.data.isNew && !data.data.outdated && data.data.folder) {
    return { background: '#33cc33' };
  }
  if (!data.data.isNew && data.data.outdated && data.data.folder) {
    return { background: '#ff9900' };
  }
  if (data.data.outdated) {
    return { background: '#ff9900' };
  }
  if (data.data.isNew) {
    return { background: '#33cc33' };
  }
  if (data.data.folder || data.data.nodes) {
    if (data.props.selected) return { background: '#b0aeae' };
    return { background: '#d1d1d1' };
  }
}

export function intelliBridgeConfigStyle(data: any) {
  if (data.data.outdated) {
    if (data.props.selected) return { background: '#FF3638' };
    return { background: '#FA9899' };
  }
  if (data.data.isNew) {
    if (data.props.selected) return { background: '#65FF28' };
    return { background: '#C2FFAA' };
  }
  if (data.data.folder) {
    for (let i = 0; i < data.data.nodes.length; i++) {
      if (data.data.nodes[i].isNew || data.data.nodes[i].outdated) {
        if (data.props.selected) return { background: '#FED531' };
        return { background: '#FFEDA8' };
      }
    }
    if (data.props.selected) return { background: '#b0aeae' };
    return { background: '#d1d1d1' };
  }
}
