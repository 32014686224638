import { Grid } from '@mui/material';
import ActionsTable from './Tables/ActionsTable';
import PagesTable from './Tables/PagesTable';
import Loader from '../../../../utils/Loader/Loader';
import { useState } from 'react';
import { IReloadFunctions } from '../Types';
import useLocales from '../../../../hooks/useLocales';

interface IActionsAndPagesManager {
  reloadFunctions: IReloadFunctions;
}

export default function ActionsAndPagesManager(props: IActionsAndPagesManager) {
  const { t } = useLocales();
  const [dataLoading, setDataLoading] = useState(false);
  return (
    <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
      <Grid item container flex={2} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('actions')}
        </Grid>
        <Grid item flex={1}>
          <ActionsTable dataLoading={dataLoading} setDataLoading={setDataLoading} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
      <Grid item container flex={2} direction="column">
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          {t('pages')}
        </Grid>
        <Grid item flex={1}>
          <PagesTable dataLoading={dataLoading} setDataLoading={setDataLoading} reloadFunctions={props.reloadFunctions} />
        </Grid>
      </Grid>
      <Loader dataIsLoading={dataLoading} errorState={false} retry={undefined} />
    </Grid>
  );
}
