// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import { PATH_DASHBOARD } from '../../../routes/paths';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TagIcon from '@mui/icons-material/Tag';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InfoIcon from '@mui/icons-material/Info';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import EuroIcon from '@mui/icons-material/Euro';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { NavSectionProps } from '../../../components/nav-section/type';
import ShieldIcon from '@mui/icons-material/Shield';
import PrintIcon from '@mui/icons-material/Print';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  projects: <AccountTreeIcon />,
  devices: <DeviceHubIcon />,
  editor: <BorderClearIcon />,
  admin: <AdminPanelSettingsIcon />,
  tag: <TagIcon />,
  deviceType: <PhonelinkSetupIcon />,
  operations: <FormatListNumberedIcon />,
  info: <InfoIcon />,
  systemSettings: <SettingsSuggestIcon />,
  ocr: <FormatShapesIcon />,
  version_management: <ManageHistoryIcon />,
  euro: <EuroIcon />,
  lunch: <RestaurantIcon />,
  shield: <ShieldIcon />,
  printer: <PrintIcon />,
  management: <ViewTimelineIcon />,
};

const navConfig: NavSectionProps['navConfig'] = [
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'projects', path: PATH_DASHBOARD.pages.projects.replace(':tabId?', ''), icon: ICONS.projects, page_uuid: 'ae9ec4b1-7d5a-41d9-ac72-9a04f7c3b898' },
      { title: 'calendar', path: PATH_DASHBOARD.pages.calendar, icon: ICONS.management, page_uuid: '58285501-8281-4897-ad1d-adf8c9baf456' },
      { title: 'devices', path: PATH_DASHBOARD.pages.deviceConfig, icon: ICONS.devices, page_uuid: '26832687-5414-4892-8df2-ba8a31cf1cc6' },
    ],
  },

  // ----------------------------------------------------------------------
  {
    subheader: 'Administration',
    items: [
      { title: 'Tags', icon: ICONS.tag, path: PATH_DASHBOARD.administration.tags, page_uuid: 'd280bdb2-ea1c-4f7a-9e26-21e18c449c03' },
      { title: 'device_types', icon: ICONS.deviceType, path: PATH_DASHBOARD.administration.deviceTypes, page_uuid: '5577058f-1282-4048-bad2-6b7e2917f2b3' },
      { title: 'operation_states', icon: ICONS.operations, path: PATH_DASHBOARD.administration.operationStates, page_uuid: 'f705668e-520c-480a-8ad7-4c2b4f4a6e81' },
      { title: 'system_configurator', icon: ICONS.systemSettings, path: PATH_DASHBOARD.administration.systemStructure, page_uuid: 'e5ce5548-772d-4724-ab0f-0cdbc81330b4' },
      { title: 'Preset-Editor', icon: ICONS.editor, path: PATH_DASHBOARD.administration.presetEditor, page_uuid: '41285870-5790-4765-bf7e-e2617e590bf2' },
      { title: 'rules_version_management', icon: ICONS.version_management, path: PATH_DASHBOARD.administration.mdsRulesVersionManagement, page_uuid: '43f86f8e-d12f-4b0c-a92e-7feeb5be5f53' },
      { title: 'offer_config', icon: ICONS.euro, path: PATH_DASHBOARD.administration.offerCreator, page_uuid: 'a30cd2b0-b2e8-4f41-be1c-303985c6e2bc' },
      { title: 'permission_manager', icon: ICONS.shield, path: PATH_DASHBOARD.administration.permissionManager, page_uuid: '3827712b-cf2b-40de-97a6-d9d352520f2e' },
    ],
  },
  {
    subheader: 'System',
    items: [{ title: 'System-Info', icon: ICONS.info, path: PATH_DASHBOARD.administration.systemState, page_uuid: 'd6186f26-aa4f-4b71-8575-923ad14b2919' }],
  },
  {
    subheader: 'Tools',
    items: [
      { title: 'optical_character_recognition', icon: ICONS.ocr, path: PATH_DASHBOARD.tools.orc, page_uuid: '6c89ac3e-e686-46cc-80c6-34f5534c4042' },
      {
        title: 'ZPL-Printer',
        icon: ICONS.printer,
        path: PATH_DASHBOARD.tools.zplPrinter,
        page_uuid: '20cb92ad-1060-4c0d-8774-2cb21603c98a',
      },
    ],
  },
  {
    subheader: '',
    items: [{ title: 'Lunch finder', icon: ICONS.lunch, path: PATH_DASHBOARD.pages.lunchFinder, page_uuid: '5afb9f13-e8e8-4973-aa0a-297cff230858' }],
  },

  // ----------------------------------------------------------------------
  /*
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: '/dashboard/user',
        icon: ICONS.user,
        children: [
          { title: 'Four', path: '/configtool/user/four' },
          { title: 'Five', path: '/configtool/user/five' },
          { title: 'Six', path: '/configtool/user/six' },
        ],
      },
    ],
  },*/
];

export default navConfig;
