import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, InputAdornment, Modal, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { handleApiError } from '../../utils/HandleApiError';
import * as notify from '../../utils/Notification';
import { changePassword } from './API/API';

interface IChangePasswordModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
}

export function ChangePasswordModal(props: IChangePasswordModal) {
  const { t } = useLocales();
  const [showPassOld, setShowPassOld] = useState(false);
  const [showPassNew, setShowPassNew] = useState(false);
  const oldPassRef = useRef<HTMLInputElement | null>(null);
  const newPassRef = useRef<HTMLInputElement | null>(null);
  const [oldPassError, setOldPassError] = useState(false);
  const [newPassError, setNewPassError] = useState(false);
  const [oldPassErrorText, setOldPassErrorText] = useState('');
  const [newPassErrorText, setNewPassErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();

  async function setNewPass() {
    setOldPassError(false);
    let oldPassText = '';
    setOldPassErrorText(oldPassText);
    setNewPassError(false);
    let newPassText = '';
    setNewPassErrorText(newPassText);
    try {
      setLoading(true);
      if (oldPassRef.current && newPassRef.current) {
        const res = (await changePassword(oldPassRef.current?.value, newPassRef.current?.value)).data;
        if (!res.old_password_ok) {
          setOldPassError(true);
          oldPassText += t('old_pass_not_ok') + ' ';
        }
        if (!res.complexity.password_ok) {
          setNewPassError(true);
          if (res.complexity.digit_error) newPassText += t('pass_digit_error') + ' ';
          if (res.complexity.length_error) newPassText += t('pass_length_error') + ' ';
          if (res.complexity.lowercase_error || res.complexity.uppercase_error) newPassText += t('pass_upper_lower_case') + ' ';
        } else if (res.old_password_ok) {
          logout();
        }
        setOldPassErrorText(oldPassText);
        setNewPassErrorText(newPassText);
        setTimeout(() => setLoading(false)); //deactivate loading animation
      }
    } catch (error) {
      notify.notifyError(t('error_changing_password'), 'id_error_changing_password');
      handleApiError(error, logout);
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          maxWidth: '40vw',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
        }}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            {t('change_password')}
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              label={t('password_old')}
              inputRef={oldPassRef}
              type={showPassOld ? 'text' : 'password'}
              name="pass_old"
              error={oldPassError}
              helperText={oldPassErrorText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassOld(!showPassOld)}>{showPassOld ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              label={t('password_new')}
              inputRef={newPassRef}
              type={showPassNew ? 'text' : 'password'}
              name="pass_new"
              error={newPassError}
              helperText={newPassErrorText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassNew(!showPassNew)}>{showPassNew ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item>
              <Button
                style={{ marginRight: 5 }}
                variant="outlined"
                onClick={() => {
                  setOldPassError(false);
                  setOldPassErrorText('');
                  setNewPassError(false);
                  setNewPassErrorText('');
                  props.setModalState(false);
                }}
              >
                {t('abort')}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={setNewPass} loading={loading}>
                {t('save')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
