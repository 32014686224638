import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { IPage, IReloadFunctions } from '../../Types';
import { Dispatch, SetStateAction, useState } from 'react';
import { scrollProps } from '../../../../../utils/ScrollProps';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import AreYouSureModal from '../../../../../utils/AreYouSureModal';
import { deletePage, updatePage } from '../../API/API';
import AddPageModal from '../Modals/AddPageModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useAuth from '../../../../../hooks/useAuth';
import useLocales from '../../../../../hooks/useLocales';
import { TypeEditInfo } from '@inovua/reactdatagrid-enterprise/types';
import * as notify from '../../../../../utils/Notification';

interface IPageTable {
  dataLoading: boolean;
  setDataLoading: Dispatch<SetStateAction<boolean>>;
  reloadFunctions: IReloadFunctions;
}

export default function PagesTable(props: IPageTable) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [contextRow, setContextRow] = useState<IPage | null>(null);
  const [addUserPageModalState, setAddUserPageModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const pages = useSelector((state: RootState) => state.permissionSlice.pages);

  const renderRowContextMenu = (menuProps: any, info: any) => {
    if (info.cellProps.data.name !== 'Exists') {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: t('delete'),
          onClick: () => {
            setContextRow(info.cellProps.data);
            setDeleteModalState(true);
          },
        },
        {
          label: t('copy'),
          onClick: () => {
            navigator.clipboard.writeText(info.cellProps.value); //copy cell value to clipboard
          },
        },
      ];
    }
  };

  async function deletePageEntry() {
    try {
      if (contextRow) {
        props.setDataLoading(true);
        await deletePage(contextRow.Id);
        props.reloadFunctions.loadPages();
        setTimeout(() => props.setDataLoading(false));
      }
    } catch (error) {
      props.setDataLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_deleting_permission_page'), 'error_deleting_page');
    }
  }

  async function onEditComplete(editInfo: TypeEditInfo) {
    //@ts-ignore
    const oldValue = editInfo.data[editInfo.columnId];
    const newValue = editInfo.value; //get new value
    if (oldValue !== newValue && newValue !== '' && newValue !== null) {
      //@ts-ignore
      const data = _.cloneDeep(editInfo.data);
      data[editInfo.columnId] = newValue; //set new data
      try {
        await updatePage(data.Id, data.name, data.pageUuid);
        props.reloadFunctions.loadPages();
      } catch (error) {
        notify.notifyError(t('error_updating_data'), 'id_error_update_page');
        handleApiError(error, logout);
      }
    } else if (oldValue !== newValue && (newValue === '' || newValue == null)) {
      notify.notifyInfo(t('no_empty_fields'), 'id_info_empty_page');
    }
  }

  return (
    <>
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_DATA_GRID_KEY}
        scrollProps={scrollProps}
        idProperty="Id"
        columns={[
          {
            name: 'Id',
            header: 'Id',
            headerAlign: 'center',
            visible: false,
          },
          {
            name: 'name',
            header: 'Name',
            headerAlign: 'center',
            defaultFlex: 1,
          },
          {
            name: 'pageUuid',
            header: 'UUID',
            headerAlign: 'center',
            defaultFlex: 1,
          },
        ]}
        defaultFilterValue={[
          { name: 'name', operator: 'contains', type: 'string', value: '' },
          { name: 'pageUuid', operator: 'contains', type: 'string', value: '' },
        ]}
        dataSource={pages}
        style={{ minHeight: '100%' }}
        enableColumnAutosize
        renderRowContextMenu={renderRowContextMenu}
        editable
        onEditComplete={onEditComplete}
        columnUserSelect
      />
      {!props.dataLoading && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <Fab onClick={() => setAddUserPageModalState(true)} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
      <AddPageModal modalState={addUserPageModalState} setModalState={setAddUserPageModalState} reloadFunctions={props.reloadFunctions} />
      <AreYouSureModal modalState={deleteModalState} setModalState={setDeleteModalState} callIfYes={deletePageEntry} />
    </>
  );
}
