import { createContext, ReactNode, useState } from 'react';
// utils
import axios from '../utils/axios';
// ----------------------------------------------------------------------

interface IAuthState {
  userId: number | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  firstName: string | null;
  lastName: string | null;
  user_uuid: string;
  email: string | null;
  pages: IActionPagePermission[];
  actions: IActionPagePermission[];
}

interface IUserData {
  data: {
    Id: number;
    user_uuid: string;
    disabled: boolean;
    isAdmin: boolean;
    email: string;
    firstname: string;
    lastname: string;
    pages: IActionPagePermission[];
    actions: IActionPagePermission[];
  };
  status: number;
  statusText: string;
}

interface IActionPagePermission {
  uuid: string;
  projectId: number;
  buildingId: number;
}

export type ContextType = {
  userId: number | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  firstName: string | null;
  lastName: string | null;
  user_uuid: string;
  email: string | null;
  pages: IActionPagePermission[];
  actions: IActionPagePermission[];
  login: (formData: FormData) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
};

const initialState: IAuthState = {
  userId: null,
  isAuthenticated: false,
  isAdmin: false,
  firstName: null,
  lastName: null,
  user_uuid: '',
  email: null,
  pages: [],
  actions: [],
};

const AuthContext = createContext<ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, setState] = useState<IAuthState>(initialState);

  const checkLogin = async () => {
    try {
      const res: IUserData = await axios.get('/auth/users/me/');
      if (
        res.status === 200 &&
        res.data &&
        (!state.isAuthenticated ||
          state.firstName === null ||
          state.lastName === null ||
          state.isAdmin !== res.data.isAdmin ||
          JSON.stringify(state.pages) !== JSON.stringify(res.data.pages) ||
          JSON.stringify(state.actions) !== JSON.stringify(res.data.actions))
      ) {
        let newState = { ...state };
        newState.userId = res.data.Id;
        newState.isAuthenticated = true;
        newState.isAdmin = res.data.isAdmin;
        newState.firstName = res.data.firstname;
        newState.lastName = res.data.lastname;
        newState.user_uuid = res.data.user_uuid;
        newState.email = res.data.email;
        newState.pages = res.data.pages;
        newState.actions = res.data.actions;
        setState(newState);
      } else if (res.status !== 200) {
        setState(initialState);
      }
    } catch {
      setState(initialState);
    }
  };

  const login = async (formData: FormData) => {
    const res = await axios.post('/auth/login', formData);
    let newState = { ...state };
    if (res.data.status === 'success') {
      newState.isAuthenticated = true;
      setState(newState);
      checkLogin();
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    await axios.post('/auth/login', {
      email,
      password,
      firstName,
      lastName,
    });
  };

  const logout = async () => {
    await axios.get('/auth/logout');
    setState(initialState);
  };

  checkLogin();

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
