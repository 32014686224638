import { Helmet } from 'react-helmet-async';

interface IPageTitle {
  title: string;
}

export default function PageTitle(data: IPageTitle) {
  return (
    <Helmet>
      <title>{`${data.title} | Configtool 2.0`}</title>
    </Helmet>
  );
}
