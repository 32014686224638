import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import UserPermissionManager from './UserPermissionManager/UserPermissionManager';
import RoleManager from './RoleManager/RoleManager';
import { useDispatch } from 'react-redux';
import { getAllActions, getAllPages, getAllRoles, getAllUsers } from './API/API';
import { setPermissionData, updateActions, updatePages, updateRoles, updateUsers } from '../../../redux/reducers/permissionSlice';
import { handleApiError } from '../../../utils/HandleApiError';
import useAuth from '../../../hooks/useAuth';
import { notifyError } from '../../../utils/Notification';
import Loader from '../../../utils/Loader/Loader';
import { IReloadFunctions } from './Types';
import { setLoadingState } from '../../../redux/reducers/loadingSlice';
import useLocales from '../../../hooks/useLocales';
import PageTitle from '../../../components/PageTitle';
import ActionsAndPagesManager from './ActionsAndPagesManager/ActionsAndPagesManager';

export default function PermissionManagerRoot(props: any) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [tab, setTab] = useState<number>(0);
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const tabStyle = {
    minHeight: '30px',
    height: '30px',
  };

  async function loadPermissionData() {
    try {
      setLoadingData(true);
      const resUsers = await getAllUsers();
      const resRoles = await getAllRoles();
      const resActions = await getAllActions();
      const resPages = await getAllPages();
      dispatch(
        setPermissionData({
          users: resUsers.data,
          roles: resRoles.data,
          actions: resActions.data,
          pages: resPages.data,
        })
      );
      setTimeout(() => setLoadingData(false));
    } catch (error) {
      notifyError(t('error_loading_data'), 'error_loading_permission_data');
      handleApiError(error, logout);
    }
  }

  function loadUsers() {
    async function load() {
      try {
        setLoadingData(true);
        const resUsers = await getAllUsers();
        dispatch(updateUsers(resUsers.data));
        setTimeout(() => setLoadingData(false));
      } catch (error) {
        notifyError(t('error_loading_data'), 'error_loading_users');
        handleApiError(error, logout);
      }
    }
    load();
  }

  function loadRoles() {
    async function load() {
      try {
        setLoadingData(true);
        const resUsers = await getAllRoles();
        dispatch(updateRoles(resUsers.data));
        setTimeout(() => setLoadingData(false));
      } catch (error) {
        notifyError(t('error_loading_data'), 'error_loading_roles');
        handleApiError(error, logout);
      }
    }
    load();
  }

  function loadActions() {
    async function load() {
      try {
        setLoadingData(true);
        const resUsers = await getAllActions();
        dispatch(updateActions(resUsers.data));
        setTimeout(() => setLoadingData(false));
      } catch (error) {
        notifyError(t('error_loading_data'), 'error_loading_actions');
        handleApiError(error, logout);
      }
    }
    load();
  }

  function loadPages() {
    async function load() {
      try {
        setLoadingData(true);
        const resUsers = await getAllPages();
        dispatch(updatePages(resUsers.data));
        setTimeout(() => setLoadingData(false));
      } catch (error) {
        notifyError(t('error_loading_data'), 'error_loading_pages');
        handleApiError(error, logout);
      }
    }
    load();
  }

  const reloadFunctions: IReloadFunctions = {
    loadUsers,
    loadRoles,
    loadActions,
    loadPages,
    setLoading: setLoadingData,
  };

  useEffect(() => {
    loadPermissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLoadingState({ isLoading: loadingData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <PageTitle title={t('permission_management')} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '5px' }}>
        <Tabs value={tab} onChange={handleChange} style={tabStyle}>
          <Tab label={t('user_roles')} style={tabStyle} />
          <Tab label={t('role_management')} style={tabStyle} />
          <Tab label={t('actions_and_pages')} style={tabStyle} />
        </Tabs>
      </Box>
      <div style={{ width: '100%', height: '100%', position: 'relative', flex: 'auto', borderColor: 'grey', borderStyle: 'solid', borderWidth: '1px', borderRadius: '4px' }}>
        <div hidden={tab !== 0} style={{ height: '100%', width: '100%' }}>
          <UserPermissionManager reloadFunctions={reloadFunctions} />
        </div>
        <div hidden={tab !== 1} style={{ height: '100%', width: '100%' }}>
          <RoleManager reloadFunctions={reloadFunctions} />
        </div>
        <div hidden={tab !== 2} style={{ height: '100%', width: '100%' }}>
          <ActionsAndPagesManager reloadFunctions={reloadFunctions} />
        </div>
      </div>
      <Loader dataIsLoading={loadingData} errorState={false} retry={undefined} />
    </div>
  );
}
