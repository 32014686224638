import { notifyError } from './Notification';

/**
 * function to handle not authenticated errors
 * @param error error
 * @param logout logout function
 */
export function handleApiError(error: any, logout: Function) {
  console.log(error);
  if (error.detail === 'not authenticated') {
    logout();
  } else if (error.detail === 'not authorized for this operation') {
    notifyError('You are not authorized for this operation!', 'error_not_authorized', true);
  }
}
