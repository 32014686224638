import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAction, IPage, IRole, IUser } from '../../pages/Administration/PermissionManager/Types';

interface IPermissionData {
  users: IUser[];
  roles: IRole[];
  actions: IAction[];
  pages: IPage[];
}

const defaultValue: IPermissionData = {
  users: [],
  roles: [],
  actions: [],
  pages: [],
};

const permissionDataSlice = createSlice({
  name: 'permissionData',
  initialState: defaultValue,
  reducers: {
    setPermissionData(state, action: PayloadAction<IPermissionData>) {
      Object.assign(state, action.payload);
    },
    updateUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },
    updateRoles(state, action: PayloadAction<IRole[]>) {
      state.roles = action.payload;
    },
    updateActions(state, action: PayloadAction<IAction[]>) {
      state.actions = action.payload;
    },
    updatePages(state, action: PayloadAction<IPage[]>) {
      state.pages = action.payload;
    },
  },
});

export const { setPermissionData, updateUsers, updateRoles, updateActions, updatePages } = permissionDataSlice.actions;

export default permissionDataSlice.reducer;
