import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
//hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard(data: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const { state }: any = useLocation();
  if (isAuthenticated) {
    if (state && state.pathName) return <Navigate to={state.pathName} />;
    return <Navigate to={PATH_DASHBOARD.pages.home} />;
  }

  return <>{data.children}</>;
}
