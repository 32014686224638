import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { IAction, IReloadFunctions, IRole } from '../../Types';
import { useState } from 'react';
import { scrollProps } from '../../../../../utils/ScrollProps';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import AreYouSureModal from '../../../../../utils/AreYouSureModal';
import AddRoleActionModal from '../Modals/AddRoleActionModal';
import { deleteActionRoleAllocation } from '../../API/API';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useLocales from '../../../../../hooks/useLocales';

interface IActionsTable {
  roleActions: IAction[];
  selRole: IRole | null;
  loadRoleData: (role: IRole) => void;
  reloadFunctions: IReloadFunctions;
}

export default function ActionsTable(props: IActionsTable) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [addRoleActionModalState, setAddRoleActionModalState] = useState(false);
  const [contextRow, setContextRow] = useState<IAction | null>(null);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loadingSlice.isLoading);

  const renderRowContextMenu = (menuProps: any, info: any) => {
    if (info.cellProps.data.name !== 'Exists') {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: t('delete'),
          onClick: () => {
            setContextRow(info.cellProps.data);
            setDeleteModalState(true);
          },
        },
        {
          label: t('copy'),
          onClick: () => {
            navigator.clipboard.writeText(info.cellProps.value); //copy cell value to clipboard
          },
        },
      ];
    }
  };

  async function deleteActionEntry() {
    try {
      if (props.selRole && contextRow) {
        props.reloadFunctions.setLoading(true);
        await deleteActionRoleAllocation(contextRow.Id, props.selRole.Id);
        props.loadRoleData(props.selRole);
      }
    } catch (error) {
      handleApiError(error, logout);
      notifyError(t('error_deleting_permission_action'), 'error_deleting_role_action');
    }
  }

  return (
    <>
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_DATA_GRID_KEY}
        scrollProps={scrollProps}
        idProperty="Id"
        columns={[
          {
            name: 'Id',
            header: 'Id',
            headerAlign: 'center',
            visible: false,
          },
          {
            name: 'name',
            header: 'Name',
            headerAlign: 'center',
            defaultFlex: 1,
          },
          {
            name: 'actionUuid',
            header: 'UUID',
            headerAlign: 'center',
            defaultFlex: 1,
          },
        ]}
        defaultFilterValue={[
          { name: 'name', operator: 'contains', type: 'string', value: '' },
          { name: 'actionUuid', operator: 'contains', type: 'string', value: '' },
        ]}
        dataSource={props.selRole ? props.roleActions : []}
        style={{ minHeight: '100%' }}
        enableColumnAutosize
        renderRowContextMenu={renderRowContextMenu}
        columnUserSelect
      />
      {!isLoading && props.selRole && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <Fab onClick={() => setAddRoleActionModalState(true)} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
      <AddRoleActionModal
        modalState={addRoleActionModalState}
        setModalState={setAddRoleActionModalState}
        selRole={props.selRole}
        assignedRoleActions={props.roleActions}
        loadRoleData={props.loadRoleData}
      />
      <AreYouSureModal modalState={deleteModalState} setModalState={setDeleteModalState} callIfYes={deleteActionEntry} />
    </>
  );
}
