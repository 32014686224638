import ReactDataGrid from '@inovua/reactdatagrid-enterprise';

/**
 * override scroll bar options
 */
export const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: false,
  scrollThumbWidth: 12,
  scrollThumbOverWidth: 20,
});
