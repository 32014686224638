import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { ChangePasswordModal } from '../../../pages/auth/ChangePasswordModal';

// ----------------------------------------------------------------------

/*
const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: '/',
  },
];
*/
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [avatar, setAvatar] = useState({});
  const auth = useAuth();
  const [passwordModal, setPasswordModal] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (auth.firstName !== null && auth.lastName !== null) {
      setAvatar({
        sx: {
          bgcolor: '#1264b7',
          color: '#ffffff',
        },
        children: `${auth.firstName[0]}${auth.lastName[0]}`,
      });
    }
  }, [auth]);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar {...avatar} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {auth.firstName} {auth.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.email}
          </Typography>
        </Box>
        <MenuItem sx={{ m: 1 }} onClick={() => setPasswordModal(true)}>
          {t('change_password')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {/*
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
          */}
        <MenuItem sx={{ m: 1 }} onClick={() => auth.logout()}>
          {t('logout')}
        </MenuItem>
      </MenuPopover>
      <ChangePasswordModal modalState={passwordModal} setModalState={setPasswordModal} />
    </>
  );
}
