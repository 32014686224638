import { createContext, ReactNode, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';
import axios from '../utils/axios';
import { handleApiError } from '../utils/HandleApiError';
import * as notify from '../utils/Notification';

interface ISettingsDistributor {
  sasToken: string;
}

type DistributorProviderProps = {
  children: ReactNode;
};

export const DistributorContext = createContext<ISettingsDistributor>({
  sasToken: '',
});

export function getSasToken() {
  return axios.get('azure/getsastoken');
}

export function SettingsDistributor({ children }: DistributorProviderProps) {
  const [sasToken, setSasToken] = useState('');
  const { logout, isAuthenticated } = useAuth();
  const { t } = useLocales();

  async function loadSasToken() {
    if (isAuthenticated) {
      try {
        const res = await getSasToken();
        setSasToken(res.data.sastoken);
      } catch (error) {
        notify.notifyError(t('error_loading_data'), 'id_error_reqeust_sasToken');
        handleApiError(error, logout);
      }
    }
  }

  useEffect(() => {
    loadSasToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <DistributorContext.Provider
      value={{
        sasToken: sasToken,
      }}
    >
      {children}
    </DistributorContext.Provider>
  );
}
