import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { IReloadFunctions, IRoleData, ITreeStructure, IUser } from '../../Types';
import { useState } from 'react';
import { scrollProps } from '../../../../../utils/ScrollProps';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import AddUserRoleModal from '../Modals/AddUserRoleModal';
import AreYouSureModal from '../../../../../utils/AreYouSureModal';
import { deleteUserRoleAllocation } from '../../API/API';
import useAuth from '../../../../../hooks/useAuth';
import { notifyError } from '../../../../../utils/Notification';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useLocales from '../../../../../hooks/useLocales';
import { highlightFolders } from '../../../../../utils/TableStyle';

interface IRolesTable {
  usersRoles: ITreeStructure[];
  selUser: IUser | null;
  loadUserData: (user: IUser) => void;
  reloadFunctions: IReloadFunctions;
}

export default function RolesTable(props: IRolesTable) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [addUserRoleModalState, setAddUserRoleModalState] = useState(false);
  const [contextRow, setContextRow] = useState<IRoleData | null>(null);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loadingSlice.isLoading);

  const renderRowContextMenu = (menuProps: any, info: any) => {
    if (!info.cellProps.data.folder) {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: t('delete'),
          onClick: () => {
            setContextRow(info.cellProps.data.roleData);
            setDeleteModalState(true);
          },
        },
        {
          label: t('copy'),
          onClick: () => {
            navigator.clipboard.writeText(info.cellProps.value); //copy cell value to clipboard
          },
        },
      ];
    }
  };

  async function deleteRole() {
    try {
      if (props.selUser && contextRow) {
        props.reloadFunctions.setLoading(true);
        await deleteUserRoleAllocation(props.selUser!.Id, contextRow.Id, contextRow.projectId, contextRow.buildingId);
        props.loadUserData(props.selUser);
      }
    } catch (error) {
      props.reloadFunctions.setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_deleting_permission_role'), 'error_removing_page');
    }
  }

  return (
    <>
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_DATA_GRID_KEY}
        scrollProps={scrollProps}
        idProperty="Id"
        treeColumn="name"
        columns={[
          {
            name: 'Id',
            header: 'Id',
            headerAlign: 'center',
            visible: false,
          },
          {
            name: 'name',
            header: 'Name',
            headerAlign: 'center',
            defaultFlex: 1,
          },
        ]}
        defaultFilterValue={[{ name: 'name', operator: 'contains', type: 'string', value: '' }]}
        dataSource={props.selUser ? props.usersRoles : []}
        style={{ minHeight: '100%' }}
        enableColumnAutosize
        renderRowContextMenu={renderRowContextMenu}
        rowStyle={highlightFolders}
        defaultSortInfo={{ name: 'name', dir: 1 }}
        columnUserSelect
      />
      {!isLoading && props.selUser && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <Fab onClick={() => setAddUserRoleModalState(true)} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
      <AddUserRoleModal modalState={addUserRoleModalState} setModalState={setAddUserRoleModalState} selUser={props.selUser} assignedUserRoles={props.usersRoles} loadUserData={props.loadUserData} />
      <AreYouSureModal modalState={deleteModalState} setModalState={setDeleteModalState} callIfYes={deleteRole} />
    </>
  );
}
