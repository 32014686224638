import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';
import useAuth from '../hooks/useAuth';
import { NavListProps } from '../components/nav-section/type';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

type IPermissionGuard = {
  children: React.ReactNode;
  hasContent?: boolean;
  permissionUuid?: string;
  group?: {
    subheader: string;
    items: NavListProps[];
  };
};

export default function PermissionGuard({ children, permissionUuid, hasContent, group }: IPermissionGuard) {
  const { t } = useLocales();
  const user = useAuth();

  if (user.isAdmin) return <>{children}</>;

  const accessToPage = user.pages.some((page) => page.uuid === permissionUuid);
  if (accessToPage) return <>{children}</>;

  const accessToAction = user.actions.some((action) => action.uuid === permissionUuid);
  if (accessToAction) return <>{children}</>;

  const accessToGroup = group?.items.some((item) => user.pages.some((page) => page.uuid === item.page_uuid));
  if (accessToGroup) return <>{children}</>;

  return hasContent ? (
    <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          {t('permission_denied')}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>{t('no_permission')}</Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
    </Container>
  ) : null;
}
