import axios from '../../../../utils/axios';

//--------------------------- get ---------------------------

export function getAllUsers() {
  return axios.get('permissions/users/all');
}

export function getProjects() {
  return axios.get('project/projects');
}

export function getBuildings(projectId: number) {
  return axios.get('project/' + projectId + '/buildings');
}

export function getAllRoles() {
  return axios.get('permissions/roles/all');
}

export function getAllActions() {
  return axios.get('permissions/actions/all');
}

export function getAllPages() {
  return axios.get('permissions/pages/all');
}

export function getUsersRoles(userId: number) {
  return axios.get('permissions/roles/' + userId);
}

export function getRoleActions(roleId: number) {
  return axios.get('permissions/roleActions/' + roleId);
}

export function getRolePages(roleId: number) {
  return axios.get('permissions/rolePages/' + roleId);
}

export function getTwoFactorQrCode(userId: number) {
  return axios.get('users/twoFactorQR/' + userId);
}

//--------------------------- add ---------------------------

export function addUser(firstname: string, lastname: string, email: string) {
  return axios.post('permissions/add/user', {
    firstname: firstname,
    lastname: lastname,
    email: email,
  });
}

export function addRole(name: string) {
  return axios.post('permissions/add/role', {
    name: name,
  });
}

export function addAction(name: string, uuid: string) {
  return axios.post('permissions/add/action', {
    name: name,
    uuid: uuid,
  });
}

export function addPage(name: string, uuid: string) {
  return axios.post('permissions/add/page', {
    name: name,
    uuid: uuid,
  });
}

//--------------------------- add allocation ---------------------------

export function addUserRoleAllocation(userId: number, roleId: number, projectId: number, buildingId: number) {
  return axios.post('permissions/add/allocation/userRole', {
    userId: userId,
    roleId: roleId,
    projectId: projectId,
    buildingId: buildingId,
  });
}

export function addActionRoleAllocation(actionId: number, roleId: number) {
  return axios.post('permissions/add/allocation/actionRole', {
    actionId: actionId,
    roleId: roleId,
  });
}

export function addPageRoleAllocation(pageId: number, roleId: number) {
  return axios.post('permissions/add/allocation/pageRole', {
    pageId: pageId,
    roleId: roleId,
  });
}

//--------------------------- delete ---------------------------

export function deleteUser(userId: number) {
  return axios.delete('permissions/delete/user/' + userId);
}

export function deleteRole(roleId: number) {
  return axios.delete('permissions/delete/role/' + roleId);
}

export function deleteAction(actionId: number) {
  return axios.delete('permissions/delete/action/' + actionId);
}

export function deletePage(pageId: number) {
  return axios.delete('permissions/delete/page/' + pageId);
}

//--------------------------- delete allocation ---------------------------

export function deleteUserRoleAllocation(userId: number, roleId: number, projectId: number, buildingId: number) {
  return axios.delete('permissions/delete/allocation/userRole/' + userId + '/' + roleId + '/' + projectId + '/' + buildingId);
}

export function deleteActionRoleAllocation(actionId: number, roleId: number) {
  return axios.delete('permissions/delete/allocation/actionRole/' + actionId + '/' + roleId);
}

export function deletePageRoleAllocation(pageId: number, roleId: number) {
  return axios.delete('permissions/delete/allocation/pageRole/' + pageId + '/' + roleId);
}

//--------------------------- update ---------------------------

export function updateAction(Id: number, name: string, actionUuid: string) {
  return axios.post('permissions/update/action/' + Id, {
    name: name,
    actionUuid: actionUuid,
  });
}

export function updatePage(Id: number, name: string, pageUuid: string) {
  return axios.post('permissions/update/page/' + Id, {
    name: name,
    pageUuid: pageUuid,
  });
}

export function updateUserState(userId: number, state: boolean, isAdmin: boolean) {
  return axios.post('permissions/update/user/state/' + userId, {
    state: state,
    isAdmin: isAdmin,
  });
}
