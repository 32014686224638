import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { addPageRoleAllocation } from '../../API/API';
import { IPage, IRole } from '../../Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useLocales from '../../../../../hooks/useLocales';

interface IAddUserPageModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  selRole: IRole | null;
  assignedRolePages: IPage[];
  loadRoleData: (role: IRole) => void;
}

export default function AddRolePageModal(props: IAddUserPageModal) {
  const { t, currentLang } = useLocales();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selPage, setSelPage] = useState<IPage | null>(null);
  const pages = useSelector((state: RootState) => state.permissionSlice.pages);

  async function onSubmit() {
    try {
      if (props.selRole && selPage) {
        setLoading(true);
        await addPageRoleAllocation(selPage.Id, props.selRole.Id);
        props.loadRoleData(props.selRole);
        setLoading(false);
        props.setModalState(false);
        setSelPage(null);
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_adding_permission_role'), 'error_adding_role_pages');
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '450px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid container direction="column" alignItems="center" style={{ padding: 20, width: '100%' }}>
          {currentLang.value === 'de' && <Grid item>Der Benutzerrolle {props.selRole ? props.selRole.name : ''} eine Seite freischalten</Grid>}
          {currentLang.value === 'en' && <Grid item>Add page to user role {props.selRole ? props.selRole.name : ''}</Grid>}
          <div style={{ marginTop: 15, width: '100%' }}>
            <Autocomplete
              disablePortal
              value={selPage}
              options={pages.filter((role: IRole) => !props.assignedRolePages.some((ass: IPage) => JSON.stringify(role) === JSON.stringify(ass)))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Seite" />}
              onChange={(event, newValue) => setSelPage(newValue)}
              fullWidth
            />
          </div>
          <Grid item style={{ marginTop: 40 }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={onSubmit} loading={loading} disabled={selPage === null}>
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
