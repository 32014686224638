// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DASHBOARD_ADMIN = ROOTS_DASHBOARD + '/administration';
const ROOTS_DASHBOARD_TOOLS = ROOTS_DASHBOARD + '/tools';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  pages: {
    home: path(ROOTS_DASHBOARD, '/home'),
    projects: path(ROOTS_DASHBOARD, '/projects/:tabId?'),
    projectSites: path(ROOTS_DASHBOARD, '/projectSites/:projectId/:tabId?'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    deviceConfig: path(ROOTS_DASHBOARD, '/devices'),
    databoxConfig: path(ROOTS_DASHBOARD, '/config'),
    deviceSetup: path(ROOTS_DASHBOARD, '/deviceSetup/:deviceId'),
    lunchFinder: path(ROOTS_DASHBOARD, '/lunchFinder'),
  },
  administration: {
    administration: path(ROOTS_DASHBOARD_ADMIN, '/root'),
    tags: path(ROOTS_DASHBOARD_ADMIN, '/tags'),
    deviceTypes: path(ROOTS_DASHBOARD_ADMIN, '/deviceTypes'),
    operationStates: path(ROOTS_DASHBOARD_ADMIN, '/operationStates'),
    systemState: path(ROOTS_DASHBOARD_ADMIN, '/systemState'),
    systemStructure: path(ROOTS_DASHBOARD_ADMIN, '/systemStructure'),
    loraDeviceRegistration: path(ROOTS_DASHBOARD_ADMIN, '/loraDeviceRegistration'),
    presetEditor: path(ROOTS_DASHBOARD_ADMIN, '/presetEditor'),
    mdsRulesVersionManagement: path(ROOTS_DASHBOARD_ADMIN, '/rulesVersionManagement'),
    offerCreator: path(ROOTS_DASHBOARD_ADMIN, '/offerCreator'),
    permissionManager: path(ROOTS_DASHBOARD_ADMIN, '/permissionManager'),
  },
  tools: {
    orc: path(ROOTS_DASHBOARD_TOOLS, '/ocr'),
    zplPrinter: path(ROOTS_DASHBOARD_TOOLS, '/zplPrinter'),
  },
};
