import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, Modal, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { addActionRoleAllocation } from '../../API/API';
import { IAction, IRole } from '../../Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useLocales from '../../../../../hooks/useLocales';

interface IAddRoleActionModal {
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  selRole: IRole | null;
  assignedRoleActions: IAction[];
  loadRoleData: (role: IRole) => void;
}

export default function AddRoleActionModal(props: IAddRoleActionModal) {
  const { t, currentLang } = useLocales();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selAction, setSelAction] = useState<IAction | null>(null);
  const actions = useSelector((state: RootState) => state.permissionSlice.actions);

  async function onSubmit() {
    try {
      if (props.selRole && selAction) {
        setLoading(true);
        await addActionRoleAllocation(selAction.Id, props.selRole.Id);
        props.loadRoleData(props.selRole);
        setLoading(false);
        props.setModalState(false);
        setSelAction(null);
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, logout);
      notifyError(t('error_adding_permission_action'), 'error_adding_role_action');
    }
  }

  return (
    <Modal open={props.modalState}>
      <div
        style={{
          background: '#ffffff',
          borderColor: 'grey',
          borderStyle: 'solid',
          borderRadius: 10,
          width: '450px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid container direction="column" alignItems="center" style={{ padding: 20, width: '100%' }}>
          {currentLang.value === 'de' && <Grid item>Der Benutzerrolle {props.selRole ? props.selRole.name : ''} eine Aktion freischalten</Grid>}
          {currentLang.value === 'en' && <Grid item>Add action to user role {props.selRole ? props.selRole.name : ''}</Grid>}
          <div style={{ marginTop: 15, width: '100%' }}>
            <Autocomplete
              disablePortal
              value={selAction}
              options={actions.filter((role: IRole) => !props.assignedRoleActions.some((ass: IRole) => JSON.stringify(role) === JSON.stringify(ass)))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Aktion" />}
              onChange={(event, newValue) => setSelAction(newValue)}
              fullWidth
            />
          </div>
          <Grid item style={{ marginTop: 40 }}>
            <Button
              style={{ marginRight: 5 }}
              variant="outlined"
              onClick={() => {
                props.setModalState(false);
              }}
            >
              {t('abort')}
            </Button>
            <LoadingButton variant="outlined" style={{ marginLeft: 5 }} onClick={onSubmit} loading={loading} disabled={selAction === null}>
              {t('save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
