import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number, locale: Locale) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: locale,
  });
}

export function getLastUpdateStringMinutes(lastUpdateMinutes: number | null) {
  if(lastUpdateMinutes === null){
    return "--"
  }
  const days = Math.trunc(lastUpdateMinutes / 24 / 60);
  const hours = Math.trunc((lastUpdateMinutes / 60) % 24);
  const minutes = lastUpdateMinutes % 60;
  let text = minutes + 'min';
  if (hours > 0 || days > 0) {
    text = hours + 'h ' + text;
  }
  if (days > 0) {
    text = days + 'd ' + text;
  }
  return text;
}