import { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

type CollapseDrawerContextProps = {
  isCollapse: boolean;
  onToggleCollapse: VoidFunction;
};

const initialState: CollapseDrawerContextProps = {
  isCollapse: false,
  onToggleCollapse: () => {},
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const oldCollapseState = localStorage.getItem('navbarCollapseState');
  const [collapse, setCollapse] = useState(oldCollapseState === 'true');

  const handleToggleCollapse = () => {
    const newCollapseState = !collapse;
    setCollapse(newCollapseState);
    localStorage.setItem('navbarCollapseState', String(newCollapseState));
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse,
        onToggleCollapse: handleToggleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
