import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, Typography, Tooltip } from '@mui/material';
// type
import { NavItemProps } from '../type';
//guards
import PermissionGuard from '../../../guards/PermissionGuard';
//
import { forwardRef } from 'react';
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle, ListItemStyleProps } from './style';
import { isExternalLink } from '..';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

// HANDLE SHOW ITEM BY PERMISSION
const ListItem = forwardRef<HTMLDivElement & HTMLAnchorElement, ListItemStyleProps>((props, ref) => (
  <PermissionGuard permissionUuid={props.page_uuid}>
    <ListItemStyle {...props} ref={ref}>
      {props.children}
    </ListItemStyle>
  </PermissionGuard>
));

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemProps) {
  const { t } = useLocales();
  const { title, path, icon, info, children, disabled, caption, page_uuid } = item;
  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={t(title)}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeRoot={active} disabled={disabled} page_uuid={page_uuid}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={<Link />} href={path} target="_blank" rel="noopener" disabled={disabled} page_uuid={page_uuid}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeRoot={active} disabled={disabled} page_uuid={page_uuid}>
      {renderContent}
    </ListItem>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
  const { t } = useLocales();
  const { title, path, info, children, disabled, caption, page_uuid } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={t(title)}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography noWrap variant="caption" component="div" sx={{ textTransform: 'initial', color: 'text.secondary' }}>
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} page_uuid={page_uuid}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={<Link />} href={path} target="_blank" rel="noopener" subItem disabled={disabled} page_uuid={page_uuid}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeSub={active} subItem disabled={disabled} page_uuid={page_uuid}>
      {renderContent}
    </ListItem>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

function ArrowIcon({ open }: ArrowIconProps) {
  return <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ width: 16, height: 16, ml: 1 }} />;
}
