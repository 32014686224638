import { IToken } from '../../pages/ProjectManagement/Types/Types';
import {
  IDeviceType,
  IIotypes,
  ITag_aggregation,
  ITag_dp,
  ITag_medium,
  ITag_position,
  ITag_sector,
  ITag_status,
  ITag_subsys1,
  ITag_subsys2,
  ITag_superior,
  ITag_sys,
  ITag_units,
} from '../../pages/ProjectManagement/Types/Metadata';
import { IDevice } from '../../pages/ProjectManagement/Types/Devices';
import { IBuilding } from '../../pages/ProjectManagement/Types/Buildings';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IAllTagsArrayRedux {
  unit: ITag_units[] | null;
  tag_status: ITag_status[] | null;
  tag_sys: ITag_sys[] | null;
  tag_superior: ITag_superior[] | null;
  tag_subsys1: ITag_subsys1[] | null;
  tag_subsys2: ITag_subsys2[] | null;
  tag_medium: ITag_medium[] | null;
  tag_position: ITag_position[] | null;
  tag_sector: ITag_sector[] | null;
  tag_dp: ITag_dp[] | null;
  tag_aggregation: ITag_aggregation[] | null;
  iotypes: IIotypes[] | null;
  tokens: IToken[] | null;
  gateways: IDevice[] | null;
  buildings: IBuilding[] | null;
  deviceTypes: IDeviceType[] | null;
}

const defaultState: IAllTagsArrayRedux = {
  unit: null,
  tag_status: null,
  tag_sys: null,
  tag_superior: null,
  tag_subsys1: null,
  tag_subsys2: null,
  tag_medium: null,
  tag_position: null,
  tag_sector: null,
  tag_dp: null,
  tag_aggregation: null,
  iotypes: null,
  tokens: null,
  gateways: null,
  buildings: null,
  deviceTypes: null
};

const metadataSlice = createSlice({
  name: 'metadata',
  initialState: defaultState,
  reducers: {
    setMetadataStore(state, action: PayloadAction<IAllTagsArrayRedux>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setMetadataStore } = metadataSlice.actions;
export default metadataSlice.reducer;
