import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { IReloadFunctions, IRole } from '../../Types';
import { Dispatch, SetStateAction, useState } from 'react';
import { scrollProps } from '../../../../../utils/ScrollProps';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import AreYouSureModal from '../../../../../utils/AreYouSureModal';
import { deleteRole } from '../../API/API';
import { TypeRowProps } from '@inovua/reactdatagrid-enterprise/types';
import AddRoleModal from '../Modals/AddRoleModal';
import { RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import useAuth from '../../../../../hooks/useAuth';
import { handleApiError } from '../../../../../utils/HandleApiError';
import { notifyError } from '../../../../../utils/Notification';
import useLocales from '../../../../../hooks/useLocales';

interface IRolesTable {
  setSelRole: Dispatch<SetStateAction<IRole | null>>;
  reloadFunctions: IReloadFunctions;
}

export default function RolesTable(props: IRolesTable) {
  const { t } = useLocales();
  const { logout } = useAuth();
  const [addRoleModalState, setAddRoleModalState] = useState(false);
  const [contextRow, setContextRow] = useState<IRole | null>(null);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const roles = useSelector((state: RootState) => state.permissionSlice.roles);
  const isLoading = useSelector((state: RootState) => state.loadingSlice.isLoading);

  const renderRowContextMenu = (menuProps: any, info: any) => {
    if (info.cellProps.data.name !== 'Exists') {
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: t('delete'),
          onClick: () => {
            setContextRow(info.cellProps.data);
            setDeleteModalState(true);
          },
        },
        {
          label: t('copy'),
          onClick: () => {
            navigator.clipboard.writeText(info.cellProps.value); //copy cell value to clipboard
          },
        },
      ];
    }
  };

  async function deleteRoleEntry() {
    try {
      if (contextRow) {
        props.reloadFunctions.setLoading(true);
        await deleteRole(contextRow.Id);
        props.reloadFunctions.loadRoles();
        props.setSelRole(null);
      }
    } catch (error) {
      handleApiError(error, logout);
      notifyError(t('error_deleting_permission_role'), 'error_deleting_role');
    }
  }

  return (
    <>
      <ReactDataGrid
        licenseKey={process.env.REACT_APP_DATA_GRID_KEY}
        scrollProps={scrollProps}
        idProperty="Id"
        columns={[
          {
            name: 'Id',
            header: 'Id',
            headerAlign: 'center',
            visible: false,
          },
          {
            name: 'name',
            header: 'Name',
            headerAlign: 'center',
            defaultFlex: 1,
          },
        ]}
        defaultFilterValue={[{ name: 'name', operator: 'contains', type: 'string', value: '' }]}
        dataSource={roles}
        style={{ minHeight: '100%' }}
        enableColumnAutosize
        onRowClick={(rowProps: TypeRowProps, event: MouseEvent) => {
          props.setSelRole(rowProps.data);
        }}
        renderRowContextMenu={renderRowContextMenu}
        enableSelection
        columnUserSelect
      />
      {!isLoading && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <Fab onClick={() => setAddRoleModalState(true)} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
      <AddRoleModal modalState={addRoleModalState} setModalState={setAddRoleModalState} reloadFunctions={props.reloadFunctions} />
      <AreYouSureModal modalState={deleteModalState} setModalState={setDeleteModalState} callIfYes={deleteRoleEntry} />
    </>
  );
}
